'use client';

// import { Session } from 'next-auth';

import { useEffect } from 'react';

import { useSearchParams } from 'next/navigation';

import { messages } from '@/localization';
import { userUIStore } from '@/stores/userUIStore';

import HomeTab from './HomeTab';
import { useToast } from '../ui/use-toast';

const HomeMain = () => {
  const { isSidebarOpen } = userUIStore();
  const searchParams = useSearchParams();
  const cont = searchParams.get('cont');
  const { toast } = useToast();

  const messagesLimits: Record<number, string> = {
    1: messages.casinoLimit,
    2: messages.sportsLimit,
    3: messages.withdrawalLimit,
    4: messages.depositLimit,
  };

  useEffect(() => {
    if (cont && messagesLimits[Number(cont)]) {
      toast({
        title: 'Informacion',
        description: messagesLimits[Number(cont)],
      });
    }
    window.history.replaceState({}, '', '/');
  }, [cont, toast]);
  // const registration = searchParams.get('reg');
  // const deposit = searchParams.get('deposit');

  return (
    <div className={`${!isSidebarOpen ? 'pl-72' : 'pl-20'} w-full pt-20 max-lg:pl-0 max-lg:pt-[4.2rem]`}>
      <HomeTab />
    </div>
  );
};

export default HomeMain;
